import React from 'react';

const Classes = () => {
    return (
        <div>
            <h2>Classes</h2>
            <small>Group Classes are run by Erin periodically. Check the calendar below and email <a href="mailto: erinstartnow@gmail.com">erinstartnow@gmail.com</a> to confirm your spot! Locations/times may change, so be sure to double check.</small><br />
            <small>$20 per class (cash or Venmo) - and your first class is totally free!</small>
            <div className="big-calendar fluid-container text-center d-none d-md-inline">
                <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=N3FudnZ1cGhtbjZydXJrYW0zdTlvYTI2Ym9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23039BE5&amp;color=%230B8043" style={{border:"solid 1px #777"}} title="Large Class Schedule" width="90%" height="650px" frameborder="0" scrolling="no"></iframe>
            </div>
            <div className="small-calendar fluid-container text-center d-inline d-md-none">
            <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=N3FudnZ1cGhtbjZydXJrYW0zdTlvYTI2Ym9AZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23E4C441&amp;color=%230B8043&amp;mode=AGENDA"  style={{border:"solid 1px #777" }}title="Small Class Schedule" width="90%" height="600" frameborder="0" scrolling="no"></iframe>
            </div>
        </div>
    );
}

export default Classes;
