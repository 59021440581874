import { InlineWidget } from "react-calendly";
import training from "../content/home/15.png"
import workout from "../content/home/14.png"
import erin from "../content/home/Erin.jpeg"
import group from "../content/home/16.png"
import Testimonials from "./Testimonials";
import {Link} from 'react-router-dom'
import React, {useEffect} from 'react';
import background from '../content/home/redwood-road.jpg'


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])
    return (
        <div>
            <div className="jumbotron jumbotron-fluid home"
                 style={{background: `url(${background}) no-repeat center center fixed`,
                        backgroundSize: "cover"
                 }}>
                    <h2 className="title  col-12 mx-auto my-auto">Empowerment | Preventative Strengthening | Joyful Movement</h2>
                <hr/>
                <h2>Services Offered</h2>
                <hr style={{color:"white",height:"1px",border:"none"}}/>
                <div className='container row mx-auto'>
                    <div className='col-md-4'>
                        <div>
                            <img src={workout} alt="icon"/>
                        </div>
                        <h3>Workout Plans</h3>
                        <p>personalized to your goals, body, and life with 1:1 support</p>
                    </div>
                    <div className='col-md-4'>
                        <div>
                            <img src={training} alt="icon"/>
                        </div>
                        <h3>Training Sessions</h3>
                        <p>in-person and virtual 1:1 sessions with Erin</p>
                    </div>
                    <div className='col-md-4'>
                        <div>
                            <img src={group} alt="icon"/>
                        </div>
                        <h3>Group Classes</h3>
                        <p>in-person and virtual community classes</p>
                    </div>
                </div>
                <hr/>
            </div>
            <div className='fluid-container py-2 mx-auto' style={{backgroundColor: '#0C5B81', color: 'white' }}>
                <div className="container">
                    <h2 className='text-left'>About Erin</h2>
                </div>
                <div className="row">
                <div className="col-md-5 col-12 mx-auto img-container">
                    <img className="img-fluid" src={erin} alt="Erin Geraghty, DPT"/>
                </div>
                <div className="col-md-5 col-10 mx-auto my-auto py-3" >
                    <p>
                    Erin is a Doctor of Physical Therapy who seeks to make lifelong fitness accessible and sustainable no matter a person's background, while simultaneously making it fun, engaging, and safe. Erin believes that her intimate understanding of anatomy and kinesiology are key to facilitating safe and effective training, preventing injuries, and selecting an appropriate approach for each individual. In addition to her expertise in movement science, positive energy and authenticity are key to Erin’s approach as she supports clients seeking strength, empowerment, and joy through movement. 
                    </p>
                    <Link className='btn btn-primary reactLink' to="/about">Learn More</Link>
                </div>
                </div>    
        </div>
        
            <div className="container">
                <div className="row">
                    <h2 className='text-left col-10'>Book a Free Consultation w/ Erin</h2>
                
                <div className="col-6 mx-auto h-50" >
                    <InlineWidget url="https://calendly.com/startnowcoaching" />
                </div>
                </div>
            </div>
        

            <Testimonials />
            <div className="spacer">
                
                </div>
        </div>
    );
}

export default Home;
