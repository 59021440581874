const posts = [
  {
    title: "Finding the Strength to Start",
    date: "11/25/2020",
    subtitle: "Starting with why",
    content: <div className="container text-left">
    <p>When I set my sights on becoming a PT, I always imagined I would work with athletes or at least practice in an outpatient orthopedic setting, but as I made my way through different coursework in school and ventured out into my clinical rotations, I fell in love with the world of neurological rehabilitation (think treating patients who have suffered a stroke, spinal cord injury, or traumatic brain injury). I remember feeling particularly excited by what I was learning during our unit in school on spinal cord injuries. When I had a rotation at an inpatient rehabilitation hospital and worked primarily with spinal cord injured patients, I felt what I imagine is what people describe when they talk about a “passion” or a “calling.” I feel extremely lucky that I now work in what morphed into my dream job over the years; I work at an inpatient rehabilitation hospital and am currently on the spinal cord injury team.</p>
    <p>I remember talking to a mentor of mine early in my journey to becoming a PT and saying that I couldn’t imagine spending weeks working toward a patient goal of walking to the bathroom independently. I said that I wanted to help people get back to running marathons or playing competitive sports rather than “those smaller victories.” With more exposure to different types of patients and clinical practice, my perspective and my “why” as a PT shifted and I now see how much the impact of reaching what I labeled as a “small victory” can mean for someones independence, quality of life, self esteem, and more.</p>
    <p>My day to day job at the hospital is challenging and rewarding; it is both exciting and exhausting. My coworkers and I are often working toward very practical goals to improve patients’ independence, improve their safety awareness, and address their balance deficits.This work fills me up and it feels like the space I should be right now in my life as physical therapist. That being said, I have another space which I feel energized by and pulled to pursue. In addition to my background in physical therapy, I love working out and I feel that I have so much to offer with my experience of playing competitive sports and working out on my own for many years. I specifically see a need for programs designed with women in mind and with the intention of empowering women to have the confidence to either initiate strength training altogether or take their current approach to the next level.</p>
    <p>So many of my friends and my mom’s friends have never set foot in a weight room or have no resistance training in their fitness routines whatsoever. (Note: resistance training can include free weights, weight machines, resistance bands, or simply your own body weight). There seem to be 3 main ideas that present as barriers to individuals initiating resistance training. First, starting is intimidating. I felt very intimidated when I first began exploring strength training my freshman year of college. Beyond that, I felt embarrassed to be taking a bench in the weight room from a guy who I thought “deserved it more” since I only had 10 lb dumbbells and his were 50 lb. The truth is we each deserve to be in that space as much as anyone else and we all have to start somewhere even if it’s only with 2 lbs. Next, aside from the general intimidation factor of venturing into the unknown territory of resistance training, many of my friends are nervous they might injure themselves since they don’t know where to begin or whether they are using proper form. This is a real concern, but with sufficient guidance that focuses on proper form (which I’m happy to provide to you!), resistance training can actually play a big role in preventing injury. The third and final idea I hear most is that women don’t want to look “bulky” or whatever other adjective they associate with the stereotype in their mind of women body builders. Again, I understand this concern, but that is where having your “why” comes in to your personalized workout program. We can focus on different types of exercises depending on your goals and it is important to note that the primary reason I encourage women to engage in strength training is NOT for reasons that pertain to one’s aesthetic or physique but rather for its many other benefits such as maintaining bone density, improving balance, or improving sleep.</p>
    <p>I have wanted to start this blog and business to share this enthusiasm and expertise, specifically centering women and their unique needs, for a long time. Every step of the way I have met resistance to starting within myself. This resistance has come in the form of fear of failure or judgement, wanting to wait until the “perfect time,” or having too many directions I want to go in and thus not going in any direction at all. I’m finally finding the strength to simply start and see where it takes me, and I hope that if you feel a desire within you to start something new (whether it be resistance training, meditation, writing, going on a daily walk, etc) that you find the strength to StartNow.</p>
         
        </div>,
},
    {
    title: "No Gym, No Problem",
    date: "11/12/2020",
    subtitle: "K.I.S.S.",
    content: <p className='text-left'>
    <p> I have been going to the gym for the past 6 years or so, usually 5 days a week, and usually in the early morning before school (then) or work (now). When COVID-19 became a reality and the gym was no longer a safe or feasible option, I was a little worried at first that I would get out of shape or lose the progress in my strength that I had gained over so many months of consistent training. Now, more than 8 months after I have set foot in a gym, I feel stronger, fitter, and more energized than I have in a long time.</p>

    <p> I have a few main takeaways from this realization:</p>
            <ol>
            <li> <b>K.I.S.S.</b> One of my professors would often mention this acronym and, it turns out, there’s a lot of truth to it. It stands for “Keep it simple, stupid.” I love mixing up my routine with various exercises and different equipment, but sometimes the most straightforward exercises are the most effective and can often be effective even with no or minimal added weight. Going back to the basics can be best; for example, doing a circuit of exercises like push ups and squats.
           </li>
            <li>
            <b>Listen to the body.</b> I know intellectually the importance of rest for injury prevention and insuring adequate time for muscle recovery and subsequent muscle building. I also realize that sufficient rest and variable intensity plays a big role in allowing exercise to remain an enjoyable activity that I genuinely look forward to rather than something which I dread when my alarm goes off. When I was commuting dailyish to the gym, I was much more likely to push through soreness and fatigue when I probably would have been better off resting because I wanted to optimize my gym visit and make sure it was “worth it”. Lately I feel much more in touch with my body and embrace days of simply walking and looking at the changing leaves, gentle stretching and mini-solo-basement-dance parties (aka MSBDP from here on out), and quick workouts with light weights. Presently, I sprinkle these into my routine much more liberally than I used to and it feels like a better balance point for me. 
            </li> 
            <li> 
            <b>Less can be more when it comes to equipment.</b> As I mentioned before, I love trying new exercises and switching up my routine, but I do fall into the trap at times of wasting 10+ minutes (of my precious ~40 min) at the gym looking for an open squat rack, experimenting with various weight machines, or walking back and forth between different gym areas for the equipment I want to use. I got accustomed to having access to thousands of pounds of weights of every shape and weight, rows of cardio machines, resistance bands, exercise mats, foam rollers and more at my fingertips at my gym. In my “home gym” (aka an empty corner of the basement) I only have a few items–2 sets of dumbbells, 1 kettlebell, 1 thick resistance band, 1 yoga mat, 1 exercise ball, 1 pull up bar. And the truth is that there is not much I truly miss from the gym. It is amazing what a kick ass workout you can get with even just 1 dumbbell and it is often much more efficient than my previous gym workouts.
            <p>Even though the Covid-19 pandemic was a forced constraint which led me to my small yet might home gym set up, I am grateful to have this new perspective and I think it will alter my approach to my workouts for many years to come.</p>
            </li> 
            </ol>
            </p>
}, 
{
    title: "In Betweening",
    date: "8/3/2020",
    subtitle: "",
    content: 
    <p className="text-left container">
  <p> I’m sure some people can relate to how I have been feeling lately. The past few months I’ve felt as though I’m standing in a weird in between space, straddling a divide between the past and future. The divide keeps widening each day, it seems, but my legs can only stretch so far–some days it feels like I’m doing the splits across the Grand Canyon. Most days planning further than tomorrow feels futile and ruminating on the woulda, coulda, shouldas of the past makes me feel even more stuck. It’s high time to step down from the widening chasm of past and future which I’m straddling and ground myself in the present. I’ve heard it a million times and I know it to be true, but I forget so easily that all I have is right now. This life, my life, in the present moment. 
</p>

<p>I have been wanting to start a blog for quite some time and I thought it would be a good way to ground myself in the present during these unprecedented times (I’m sorry, I had to). It seems like a good formula: Sit. Think. Write. Share. Plus, I have more downtime to sit with my thoughts and feelings than I regularly do and I figured that a few other people might be juuuust bored enough to actually read some of my musings on life, fitness, wellness, and whatever else comes out when my fingers hit the keyboard.</p>

<p>
I hope you come along for the ride and that you find something relatable, enjoyable, or valuable in what I share.
</p>
  
</p>,
}]

export default posts

