import React from 'react';
import {Link} from 'react-router-dom'
import background from "../content/landing/background.jpg"
import logo from "../content/home/logo.png"

const LandingPage = () => {
    return (
        <div className="jumbotron jumbotron-fluid landing"
                 style={{background: `url(${background}) no-repeat center center fixed`,
                        backgroundSize: "cover"
                 }}>
            <div className="img-container">
                <img src={logo} alt=""/>
            </div>
            <h2 className="title  col-12 mx-auto my-5" style={{color: 'white'}}>Empowerment | Preventative Strengthening | Joyful Movement</h2>
            <Link className="btn btn-primary" to="/home">Start</Link>
        </div>
    );
}

export default LandingPage;
