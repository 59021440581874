import React, {useEffect} from 'react';
import erinTall from "../content/about/ErinTall.jpeg"
import erinShort from "../content/about/ErinShort.jpeg"
import {Link} from 'react-router-dom'

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])
    return (
        <div>
            <div className="fluid-container row mx-auto">
                <div className="col-lg-6 col-12 mx-auto my-auto py-2">
                    <div className="img-container d-none d-lg-block">
                        <img className='img-fluid' src={erinTall} alt=""/>
                    </div>
                    <div className="img-container d-block d-lg-none">
                        <img className='img-fluid' src={erinShort} alt=""/>
                    </div>
                </div>
                <div className="col-lg-6 col-12 mx-auto my-auto py-2">
                    <h2>Erin's StartNow Story</h2>
                    <p>Erin seeks to make fitness accessible, sustainable, fun, and safe for individuals of all backgrounds. As a Doctor of Physical Therapy, Erin believes that her intimate understanding of anatomy and kinesiology are key to facilitating safe and effective training, preventing injuries, and building a personalized plan for each individual. Erin hopes to highlight the benefits of resistance training beyond aesthetics, including increased bone density, improved sleep, decreased risk of injury, better posture, enhanced mood, and improved balance.</p>


                    <p>Currently, Erin spends much of her days treating individuals with neurological diagnoses (think: spinal cord injury, brain injury, stroke) in a rehabilitation hospital setting and enjoys the challenging nature of working with patients who often face significant physical impairments. Despite a continued passion for working with these populations, she felt pulled to the idea of using her background in movement science to support individuals on their fitness journey as well. Erin created StartNow with the intention of supporting individuals of all ages and abilities with personalized workout programs designed to empower with movement that inspires joy and builds strength. </p>

                    <p>
                    Erin grew up in the Bay Area where she kept active outside playing countless sports ranging from lacrosse to cross country, playing heated games of trashcan basketball with her brothers, scribbling down recipes frantically while watching Food Network on weekend mornings, laying and chatting--more than bouncing--on her trampoline, and winding through seemingly endless trails on weekend hikes with her family. She received her bachelor’s degree at the University of Notre Dame (Go Irish) and stayed in the midwest to attend Northwestern University in Chicago to pursue her Doctor of Physical Therapy degree. </p>
                    <hr/>
                    <p>I have included free downloadable guides and other resources to help you</p>
                    <Link className="btn btn-primary" to="/resources">
                        Free Resources
                    </Link>
                    <br/>
                    <br/>
                    <p>Click here for more of my writing</p>
                    <Link className="reactLink btn btn-secondary" to="/blog" >StartNow Blog</Link>
                </div>
            </div>
        </div>
    );
}

export default About;
