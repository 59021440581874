import React from "react";
import Navbar from './components/Navbar'
import './App.css';
import Body from './components/Body';
import Footer from './components/Footer'
import {Route} from "react-router-dom"
import {useLocation} from "react-router-dom";

function App() {
  console.log(useLocation())
  return (
    <div className="App">
        <Route path = "/">
        {useLocation().pathname !== '/' && <Navbar />}
          <Body/>
          <Footer/>
        </Route>
      
    </div>
  );
}

export default App;
