import React from 'react';
import Carousel from 'react-bootstrap/Carousel'


const Testimonials = () => {
    return (
        <div className="testimonials" style={{backgroundColor:"#BCBFC2"}}>
                <div className="container">
                    <h2 className="text-left">Testimonials</h2>
                </div>
        <div className="fluid-container testimonials d-none d-md-block">
        
        <Carousel className='py-4'>
  
            <Carousel.Item>
                <div className="testimonial py-4 mx-auto col-6">
                <p>
                "Erin is such a wonderful and skilled coach. Her classes are so fun and I always learn something new about my body. I have been dealing with chronic pain and her workout plans have helped me get moving again in a mindful and intentional way." 
                </p>
                <p className="text-right mr-4"> - Kathleen</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="testimonial py-4 mx-auto col-10">
                <p>
                "As someone who relied on studio classes for my strength training, I  struggled to get into an at home workout routine. After testing various online video subscriptions, I can confidently say StartNow is the best option for something that works long term. By talking with Erin about my fitness goals and current state, she created a realistic workout plan for me. With regular check ins, there is accountability which drives me to complete each workout. While the workouts are not “easy”, they are easy to commit to!"
                </p>
                <p className="text-right  mr-4"> - Emma</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="testimonial py-4 mx-auto col-6">
                <p>
                "I would walk 5 miles a day but had no core or upper body strength. Erin created a more balanced workout plan that helped me strengthen my core and upper body, as well as my glutes! Feeling stronger!"
                </p>
                <p className="text-right mr-4"> - Susan</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="testimonial py-4 mx-auto col-6">
                <p>
                "Erin is great! She tailored different workouts for my different needs (including quick 15 minute sets to squeeze in on a busy day). Her skills as a physical therapist offer an additional level of expertise I haven't had with other trainers." 
                </p>
                <p className="text-right mr-4"> - Pauline</p>
                </div>
            </Carousel.Item>
        </Carousel>
        </div>

        <div className="fluid-container testimonials d-block d-md-none">
        
        <Carousel className='py-4'>
  
            <Carousel.Item>
                <div className="py-4 mx-auto col-12">
                <p>
                "Erin is such a wonderful and skilled coach. Her classes are so fun and I always learn something new about my body. I have been dealing with chronic pain and her workout plans have helped me get moving again in a mindful and intentional way." 
                </p>
                <p className="text-right mr-4"> - Kathleen</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className=" py-4 mx-auto col-12">
                <p>
                "As someone who relied on studio classes for my strength training, I  struggled to get into an at home workout routine. After testing various online video subscriptions, I can confidently say StartNow is the best option for something that works long term. By talking with Erin about my fitness goals and current state, she created a realistic workout plan for me. With regular check ins, there is accountability which drives me to complete each workout. While the workouts are not “easy”, they are easy to commit to!"
                </p>
                <p className="text-right  mr-4"> - Emma</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className=" py-4 mx-auto col-12">
                <p>
                "I would walk 5 miles a day but had no core or upper body strength. Erin created a more balanced workout plan that helped me strengthen my core and upper body, as well as my glutes! Feeling stronger!"
                </p>
                <p className="text-right mr-4"> - Susan</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className=" py-4 mx-auto col-12">
                <p>
                "Erin is great! She tailored different workouts for my different needs (including quick 15 minute sets to squeeze in on a busy day). Her skills as a physical therapist offer an additional level of expertise I haven't had with other trainers." 
                </p>
                <p className="text-right mr-4"> - Pauline</p>
                </div>
            </Carousel.Item>
        </Carousel>
        </div>
        
        </div>
        

    );
}

export default Testimonials;
