import React from 'react';
import Home from './Home'
import About from './About'
import Classes from './Classes'
import Resources from './Resources'
import Blog from "./Blog"
import {Route} from "react-router-dom"
import LandingPage from './LandingPage';

const Body = () => {
    
    return (
        <div>
            <Route exact path="/">
                <LandingPage/>
            </Route>
            <Route path="/home">
                <Home />
            </Route>
            <Route  path="/about" component={About}/>

            <Route  path="/classes">
                <Classes />
            </Route>
            <Route  path="/resources">
                <Resources />
            </Route>
            <Route  path="/blog">
                <Blog />
            </Route>
        </div>
    );
}

export default Body;
