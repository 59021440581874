import React, {useEffect} from 'react';
import randr from '../content/Resources/reflect-and-revamp-worksheet.jpg'
import strength from '../content/Resources/strength-training-for-beginners-1.jpg'
import randrPDF from '../content/Resources/reflect-and-revamp-worksheet.pdf'
import strengthPDF from '../content/Resources/strength-training-for-beginners-1.pdf'


const Resources = () => {
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])
    return (
        <div>
            <h1>Free Resources</h1>
            <hr/>
            <div className="container">
                <h2>Guides</h2>
                <div className="row">
                    <div className="resource col-5 mx-auto py-4" style={{backgroundImage:`url(${randr})`}}>
                        <h3 style={{color:"white"}}>Reflect and Revamp<br/>Worksheet</h3>
                        <a className="btn btn-primary mb-3" href={strengthPDF} download>Download</a>
                    </div>
                    <div className="resource col-5 mx-auto py-4" style={{background:`url(${strength})`}}>
                        <h3>Beginner's Strength Training Routine</h3>
                        <a className="btn btn-primary mb-3" href={randrPDF} download>Download</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resources;
