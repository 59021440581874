import React from 'react';
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <Link className=" reactLink navbar-brand" to="/">StartNow Coaching</Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarText">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <Link className="nav-link reactLink" to="/about">About</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link reactLink" to="/resources">Resources</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link reactLink" to="/classes">Classes</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link reactLink" to="/blog">Blog</Link>
      </li>
    </ul>
    
    
  </div>
  <ul className="footer navbar-nav ml-auto social-network social-circle">
              <span className='my-auto'>Connect: </span>
              <li><a href="mailto:erinstartnow@gmail.com" className="icoFacebook" title="Email"><i className="fa fa-envelope"></i></a></li>
              <li><a href="https://www.instagram.com/start_now_coaching/" className="icoInstagram" title="instagram"><i className="fa fa-instagram"></i></a></li>
    </ul> 
</nav>
        </div>
    );
}

export default Footer;
