import React, {useState} from 'react';

const Post = ({post}) => {
    const [readMore, setReadMore] = useState(false);
    const linkName =readMore?'Read Less << ':'Read More >> '

    return (
        <div>
             <div className="card w-75 my-3 mx-auto">
                <div className="container text-left py-2">
                    <h3 className='m-auto'><b>{post.title}</b></h3>
                    <h4>{post.date}</h4>
                </div>
                <div className="container text-center">
                <button className = "w-50 my-2 btn btn-primary read-more-link" onClick={()=>{setReadMore(!readMore)}}><h2>{linkName}</h2> </button>
                {readMore && post.content}
      </div>
  </div>
        </div>
    );
}

export default Post;


