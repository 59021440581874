import React from 'react';
import logo from '../content/home/logo.png'
import info from '../content/home/info.png'
import {NavLink} from "react-router-dom"

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-primary">
            <NavLink className="navbar-brand reactLink" to="/">
                <div className="w-10 p-3"> 
                <img src={logo} alt='start now brand logo' />
                </div>
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item active">
        <NavLink className="nav-link reactLink" to="/home">Home <span className="sr-only">(current)</span></NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/about">About</NavLink>
      </li>
      <li className="nav-item hiddenNav">
      <NavLink className="nav-link" to="/classes">Classes</NavLink>
      </li>
      <li className="nav-item hiddenNav">
      <NavLink className="nav-link" to="/resources">Free Resources</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/blog">Blog</NavLink>
      </li>
    </ul>
  </div>
  <div className='d-none d-md-inline'>
    <div className="img-container">
      <img src={info} alt=""/>
    </div>
  </div>
</nav>
    );
}

export default Navbar;
