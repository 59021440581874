import React, {useEffect} from 'react';
import Post from './Post'
import posts from './blogposts'

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0); //have page appear at top of screen
  },[])
    return (
<div className="container">
  <h2 className="text-left">All Posts</h2>
      {posts.map(thisPost => {
        return (
        <Post post={thisPost}/>
        )
      })}
 

    </div>
    );
}

export default Blog;
